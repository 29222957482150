jQuery(document).ready(function ($) {
    $('.js-select2').each( function(){
        $(this).select2({
            minimumResultsForSearch: -1
        });
    })

    $('.js-select2-custom').each( function(){
        $(this).select2({
            minimumResultsForSearch: -1,
            dropdownCssClass: "js-select2-custom-dropdown"
        });
        $(this).siblings().addClass("js-select2-custom");
    })
});