import Cookies from 'js-cookie';
const closeNewsletterPopup = document.querySelector('.close.newsletter-close');
const overlayNewsletterPopup = document.querySelector('.alert.alert-dismissible.newsletter-popup-wrap');
const subscribePopup = document.querySelector('#subscribe_popup_email');
const subscribeInput = document.querySelector('#subscribe_hidden');

$(document).ready(function () {
    if ($(window).width() <= 991) {
        $('.footer-title').on('click', function () {
            if (!$(this).hasClass('_current')) {
                $('.footer-title').each(function (index, el) {
                    $(el).removeClass('_open _current');
                });
            }

            $(this).toggleClass('_open');
            $(this).addClass('_current');
        });
    }
});

if (closeNewsletterPopup || subscribePopup) {
    closeNewsletterPopup.addEventListener('click', function(e) {
        overlayNewsletterPopup.remove();
        Cookies.set('GlobalNewsletterPromo', 'hidden', { expires: 2 })
    });
}

$('#subscribe_hidden').on('change', function () {
    if ($(this).val() == 1) {
        setTimeout(function () {
            overlayNewsletterPopup.remove();
            Cookies.set('GlobalNewsletterPromo', 'hidden', {expires: 2})
        }, 2000);
    }
})

window.addEventListener('DOMContentLoaded', () => {
    let newsletterCookie = Cookies.get('GlobalNewsletterPromo');
    if (newsletterCookie == 'hidden') {
        overlayNewsletterPopup.remove();
    } else {
        overlayNewsletterPopup.style.display = "flex";
    }
});

