import 'simplebar/dist/simplebar.css';
import 'simplebar';

document.addEventListener('DOMContentLoaded', () => {
    const scrollbarContainers = document.querySelectorAll('.bf-attr-block-cont');
    scrollbarContainers.forEach((container) => {
        container.setAttribute('data-simplebar', '');
        container.setAttribute('data-simplebar-auto-hide', false);
    });
});
