import Swiper, {Navigation} from "swiper";

// $(document).ready(function () {
//     const productThumbs = new Swiper('.gallery-thumbnails', {
//         modules: [Navigation],
//         centeredSlides: true,
//         centeredSlidesBounds: true,
//         slidesPerView: 4,
//         watchOverflow: true,
//         watchSlidesVisibility: true,
//         watchSlidesProgress: true,
//         direction: 'horizontal',
//         navigation: {
//             nextEl: '.product-thumbs-nav.next',
//             prevEl: '.product-thumbs-nav.prev',
//         },
//         breakpoints: {
//             991: {
//                 slidesPerView: 4,
//                 direction: 'vertical',
//             }
//         }
//     });
// });
