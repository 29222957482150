$(document).ready(function () {
    // Search
    $('.open-search-bar').click(function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('.open-search-input').toggleClass('active');
        $('body').toggleClass('search-is-open');
        $('#search').removeClass('active');

        $('body').removeClass('menu-is-open');
        $('.burger-menu').removeClass('active');
        $('.megamenu-wrapper').removeClass('so-megamenu-active');
    });
    $('#search input[type=text]').click(function (e) {
        e.preventDefault();
        $('#search').addClass('active');
    });

    setTimeout(function () {
        $('#search>input[name="search"]').attr('autocomplete', 'off');
    }, 500);
});
