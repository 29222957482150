window.addEventListener('DOMContentLoaded', () => {
    addElRootVar('.discount-bar');
    addElRootVar('#main-header');
    addElRootVar('.breadcrumb');
});

window.addEventListener('resize', () => {
    addElRootVar('.discount-bar');
    addElRootVar('#main-header');
    addElRootVar('.breadcrumb');
});

function addElRootVar(el) {
    qEl = document.querySelector(el);
    el = el.replace(/[#.]/g, '');
    if (qEl) {
        document.documentElement.style.setProperty('--' + el + '-height', qEl.offsetHeight + 'px');
    }
}