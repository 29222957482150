$(document).ready(function () {
    $('.burger-menu').click(function (e) {
        e.preventDefault();
        $('body').toggleClass('menu-is-open');
        $(this).toggleClass('active');
        $('.megamenu-wrapper').toggleClass('so-megamenu-active');

        $('.open-search-bar').removeClass('active');
        $('.open-search-input').removeClass('active');
        $('body').removeClass('search-is-open');
        $('#search').removeClass('active');
    });

    if ($(window).width() <= 991) {
        $('.open-static-menu').click(function () {
            $(this).parent().parent().parent().toggleClass('active');
        });

        $('.with-sub-menu > a').click(function (e) {
            e.preventDefault();
            $(this).parent().siblings().removeClass('sub-menu-is-open');
            $(this).parent().toggleClass('sub-menu-is-open');
            let textCategory = $(this).parent().find('.clearfix').text();
            let imgCategory = $(this).parent().find('.clearfix img').attr('src');
            let target = $(this).parent().find('.clearfix').attr('href');
            $(".current-category-item").attr("href", target);
            $('.current-category-item-name').text('').append(textCategory);
            $('.current-category-item-image').attr('src', '').attr('src', imgCategory);
        });

        $('.back-menu-link').click(function () {
            $(this).parent().parent().parent().parent().removeClass('sub-menu-is-open');
        });
    }
});
