// import Swiper JS
import Swiper, { Autoplay, Navigation, Pagination, FreeMode, Thumbs, Zoom } from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/zoom';

window.addEventListener('DOMContentLoaded', () => {
    // homepage slider
    const homepageHero = new Swiper('.hero-slider', {
        // modules: [Autoplay],
        loop: true,
        slidesPerView: 1,
        speed: 1000,
        autoplay: {
            delay: 3500,
            disableOnInteraction: false,
        },
    });
    // homepage slider
    const doubleShowcase = new Swiper('.showcase-slider-container.double .showcase-slider', {
        modules: [Navigation],
        loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
            nextEl: '.showcase-slider-container.double .showcase-arrow.next',
            prevEl: '.showcase-slider-container.double .showcase-arrow.prev',
        },
        breakpoints: {
            1280: {
                slidesPerView: 2,
            },
        },
    });
    const singleShowcase = new Swiper('.showcase-slider-container.single .showcase-slider', {
        modules: [Navigation],
        loop: true,
        slidesPerView: 1,
        navigation: {
            nextEl: '.showcase-slider-container.single .showcase-arrow.next',
            prevEl: '.showcase-slider-container.single .showcase-arrow.prev',
        },
    });

    const productSliders = document.querySelectorAll('.products-slider');
    const nextArrows = document.querySelectorAll('.products-slider-arrow.next');
    const prevArrows = document.querySelectorAll('.products-slider-arrow.prev');
    if (productSliders.length) {
        productSliders.forEach((slider, i) => {
            const productsSlider = new Swiper(slider, {
                modules: [Navigation],
                // loop: true,
                slidesPerView: 1,
                spaceBetween: 30,
                navigation: {
                    nextEl: nextArrows[i],
                    prevEl: prevArrows[i],
                },
                breakpoints: {
                    767: {
                        slidesPerView: 2,
                    },
                    991: {
                        slidesPerView: 3,
                    },
                    1366: {
                        slidesPerView: 4,
                    },
                },
            });
        });
    }

    const homeBlogSlider = new Swiper('.home-blog-slider', {
        modules: [Pagination],
        slidesPerView: 1,
        spaceBetween: 90,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            767: {
                slidesPerView: 2,
                spaceBetween: 45,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },
            },
            1400: {
                slidesPerView: 3,
                spaceBetween: 60,
                pagination: false,
            },
            1540: {
                slidesPerView: 3,
                spaceBetween: 90,
                pagination: false,
            },
        },
    });

    const brandsSlider = new Swiper('.brands-slider', {
        modules: [Navigation],
        loop: true,
        slidesPerView: 3,
        spaceBetween: 35,
        navigation: {
            nextEl: '.brands-slider-arrow.next',
            prevEl: '.brands-slider-arrow.prev',
        },
        breakpoints: {
            1024: {
                slidesPerView: 6,
                spaceBetween: 45,
            },
            1366: {
                slidesPerView: 8,
                spaceBetween: 45,
            },
            1541: {
                slidesPerView: 12,
                spaceBetween: 55,
            },
        },
    });

    const blogInnerShowmore = new Swiper('.blogInner-slider', {
        modules: [Navigation],
        slidesPerView: 1,
        spaceBetween: 90,
        navigation: {
            nextEl: '.showmore-arrow.next',
            prevEl: '.showmore-arrow.prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
        },
    });
});
