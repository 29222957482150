import './style.scss';

import './scripts/header';
import './scripts/footer';
import './scripts/minicart';
import './scripts/filterButtons';
import './scripts/checkout';
import './scripts/sliders';
import './scripts/search';
import './scripts/menu';
import './scripts/product-carousel';
import './scripts/product-tabs';
import './scripts/customScrollBar';
import './scripts/theme';