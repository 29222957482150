$(document).ready(function () {
    $('.tablink').click(function(e){
        e.preventDefault();
        $('.tablink').removeClass('active');
        $(this).addClass('active');
        $('.tabcontent').removeClass('active');
        var target = $(this).attr('href');
        $(target).addClass('active');
    });
});
